import React, { useEffect } from 'react';
import {navigate} from 'gatsby';

import { useAPI } from '../hooks';
import { LoadingOverlay } from '../components/common';
import { PATH } from '../constants/global';
import { setUser } from '../common/utils/auth';
import { Paths } from '../constants/structure';

export default () => {
    const request = useAPI();

    const logOut = async () => {
        await request.post(PATH.AUTH.LOGOUT);
        setUser({});
        navigate(Paths.LOGIN);
    }

    useEffect(() => {
        logOut();
    }, []);

    return <LoadingOverlay />;
}
